import { useTranslation } from "react-i18next";

const usePrivilegesMap = () => {
  const { t } = useTranslation();

  return {
    all: { label: t("all"), color: "primary" },
    view: { label: t("view"), color: "default" },
    add: { label: t("add"), color: "success" },
    edit: { label: t("edit"), color: "info" },
    delete: { label: t("delete"), color: "error" },
    suggest: { label: t("suggest"), color: "warning" },
  };
};

export default usePrivilegesMap;
