import React, { useEffect, useState } from "react";
import TechnologyDetailsCard from "./TechnologyDetailsCard";
const PYTHON_URL = process.env.REACT_APP_PYTHON_URL;

const TechnologyDetails = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);

  const fetchById = async (id) => {
    try {

      const api_url = `${PYTHON_URL}/technologies/getbyid/${id}`; 
      
      const response = await fetch(api_url);
      if (!response.ok) {
        throw new Error("Failed to fetch data by ID");
      }
      const jsonData = await response.json();
      setDetails(jsonData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data by ID:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchById(id);
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <TechnologyDetailsCard result={details.result} />
    </div>
  );
};

export default TechnologyDetails;
