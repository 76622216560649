import React, { useEffect, useState } from 'react';
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

// Admin-panel components
import StatisticsCard from "./home/StatisticsCard";
import SuggestionsList from "./home/UserSuggestionsList";
import UsersLog from "./users/UserLogs";

// Icons
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

// Handlers
import { useStatisticsHandlers } from "../../handlers";

// Api service
import ApiService from "../../api/api";

const AdminPanel = () => {
  const { t } = useTranslation();

  // Use Api services for home Api handlers
  const homeApi = new ApiService(`${process.env.REACT_APP_PYTHON_URL}/home`);

  const { fetchStatistics } = useStatisticsHandlers(homeApi);
  const [statistics, setStatistics] = useState(null);

  // Dummy data for recent activities and notifications
  const recentActivities = [
    { id: 1, text: "User John Doe registered" },
    { id: 2, text: "Updated profile information" },
    { id: 3, text: "New blog post published" },
    { id: 4, text: "User Jane Smith logged in" },
  ];

  const notifications = [
    { id: 1, text: "System update: Scheduled maintenance on July 15" },
    { id: 2, text: "New feature: Enhanced user dashboard now available" },
  ];

  useEffect(() => {
    const getStatistics = async () => {
      try {
        const data = await fetchStatistics();
        setStatistics(data);
      } catch (error) {
        console.error("Failed to fetch statistics:", error);
      }
    };

    getStatistics();
  }, [fetchStatistics]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            title={t("totalUsers")}
            value={statistics?.total_users}
            color="info.light"
            Icon={PeopleIcon}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            title={t("activeUsers")}
            value={statistics?.active_users}
            color="success.light"
            Icon={HowToRegOutlinedIcon}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            title={t("userRoles")}
            value={statistics?.total_user_roles}
            color="warning.light"
            Icon={AdminPanelSettingsOutlinedIcon}
          />
        </Grid>
        <Grid item xs={8}>
          <UsersLog />
        </Grid>
        <Grid item xs={4}>
          <SuggestionsList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminPanel;
