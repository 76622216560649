import { useState } from 'react';
import { useCrudHandlers } from './crudHandlers';
import { useSnackbarHandlers } from './snackbarHandlers';

// A custom hook for handling deletion-specific logic
export const useDeleteHandlers = (api) => {

  const [deleteEntityId, setDeleteEntityId] = useState(null);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);


  // Destructuring handlers from handlers hook
  const {
    handleSnackbar,
  } = useSnackbarHandlers();

  const { handleDeleteEntity, handleDeleteSelectedEntities } = useCrudHandlers(api);

  // Initiate entity deletion
  const handleDeleteEntityInitiate = (id, isBulkDelete = false) => {
    setDeleteEntityId(id);
    setIsBulkDelete(isBulkDelete);
    setDeleteDialogOpen(true);
  };

  // Confirm entity deletion
  const handleDeleteEntityConfirm = async () => {
    try {
      const message = await handleDeleteEntity(deleteEntityId);
      handleSnackbar(message);
      setDeleteDialogOpen(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
    }
  };

  // Confirm deletion of selected entities
  const handleDeleteSelectedEntitiesConfirm = async () => {
    try {
      const message = await handleDeleteSelectedEntities();
      handleSnackbar(message);
      setDeleteDialogOpen(false);
    } catch (error) {
      handleSnackbar(error.message, "error");
    }
  };

  return {
    deleteEntityId,
    isBulkDelete,
    deleteDialogOpen,
    handleDeleteEntityInitiate,
    handleDeleteEntityConfirm,
    handleDeleteSelectedEntitiesConfirm,
    setDeleteDialogOpen,
  };
};
