import { useSnackbarHandlers } from './snackbarHandlers';
import { useStateContext } from '../context/stateContext';

// A custom hook for handling CRUD Actions
export const useCrudHandlers = (api) => {
  const { entities, setEntities, selectedRows, setSelectedRows} = useStateContext();

  // Destructuring handlers from snackbar handlers hook
  const {
    handleSnackbar,
    handleSnackbarClose
  } = useSnackbarHandlers();

  // Fetch all entities from the server
  const fetchAllEntities = async () => {
    try {
      const data = await api.fetchAll();
      setEntities(data);
    } catch (error) {
      console.error('Error fetching entities:', error);
    }
  };

  // Handle addition of a new entity
  const handleAddEntity = async (newEntity) => {
    try {
      const data = await api.add(newEntity);
      setEntities([...entities, data.entity]);
      return data.message || 'Entity added successfully!';
    } catch (error) {
      console.error('Failed to add entity:', error);
      throw error;
    }
  };

  // Handle editing of an existing entity
  const handleEditEntity = async (entityId, updatedEntity) => {
    try {
      const data = await api.edit(entityId, updatedEntity);
      setEntities(entities.map(entity => (entity.id === data.entity.id ? data.entity : entity)));
      return data.message || 'Entity updated successfully!';
    } catch (error) {
      console.error('Failed to update entity:', error);
      throw error;
    }
  };

  // Handle deletion of a entity
  const handleDeleteEntity = async (entityId) => {
    try {
      const data = await api.deleteEntity(entityId);
      setEntities(entities.filter(entity => entity.id !== entityId));
      return data.message || 'Entity deleted successfully!';
    } catch (error) {
      console.error('Failed to delete entity:', error);
      throw error;
    }
  };

  // Handle deletion of selected entities
  const handleDeleteSelectedEntities = async () => {
    try {
      const data = await api.deleteSelectedEntities(selectedRows);
      setEntities(entities.filter(entity => !selectedRows.includes(entity.id)));
      setSelectedRows([]);
      return data.message || 'Entities deleted successfully!';
    } catch (error) {
      console.error('Failed to delete entities:', error);
      throw error;
    }
  };

  // Handle saving a entity (either add or edit)
  const handleSaveEntity = async (method, entity) => {
    try {
      if (method === 'POST') {
        const message = await handleAddEntity(entity);
        handleSnackbar(message);
      } else {
        const message = await handleEditEntity(entity.id, entity);
        handleSnackbar(message);
      }
    } catch (error) {
      handleSnackbar(error.message, 'error');
    }
  };

  // Returning all the necessary functions and states for entity handling
  return {
    handleAddEntity,
    fetchAllEntities,
    handleEditEntity,
    handleSaveEntity,
    handleDeleteEntity,
    handleSnackbar,
    handleSnackbarClose,
    handleDeleteSelectedEntities,
  };
};
