import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ApiService from "../../../api/api";

const UserLogs = () => {
  const { t } = useTranslation();

  // Example data for logs (replace with your actual log data)
  const logs = [
    {
      id: 1,
      username: "user1",
      email: "user1@test.com",
      action: "Logged in",
      timestamp: "2024-07-13 10:15:00",
    },
    {
      id: 2,
      username: "user2",
      email: "user2@test.com",
      action: "Updated database",
      timestamp: "2024-07-13 11:30:00",
    },
    {
      id: 3,
      username: "user1",
      email: "user1@test.com",
      action: "Logged out",
      timestamp: "2024-07-13 10:15:00",
    },
    {
      id: 4,
      username: "user2",
      email: "user2@test.com",
      action: "Updated profile",
      timestamp: "2024-07-13 11:30:00",
    },
    {
      id: 5,
      username: "user2",
      email: "user2@test.com",
      action: "Updated profile",
      timestamp: "2024-07-13 11:30:00",
    },
  ];

  // Use Api services for logs Api handlers (replace with actual API service)
  const logsApi = new ApiService(`${process.env.REACT_APP_PYTHON_URL}/logs`);

  // Fetch logs data on component mount (simulating with useEffect)
  useEffect(() => {
    // logsApi.fetchAllLogs();
  }, []);

  // Define columns for DataGrid
  const columns = [
    { field: "username", headerName: t("username"), width: 150 },
    { field: "email", headerName: t("email"), width: 300 },
    { field: "action", headerName: t("action"), width: 300 },
    { field: "timestamp", headerName: t("timestamp"), width: 200 },
  ];

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        borderBottom: "2px solid",
        borderBottomColor: "primary.main",
      }}
    >
      <CardContent>
        <Typography sx={{ mb: 4 }} variant="h5" component="div">
          {t("userLogs")}
        </Typography>
        <DataGrid
          rows={logs}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      </CardContent>
    </Card>
  );
};

export default UserLogs;
