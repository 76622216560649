// MUI Imports
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItem,
  List,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const StyledList = styled(List)(({ theme }) => ({
  "& .MuiListItem-container": {
    border: "1px solid var(--mui-palette-divider)",
    "&:first-of-type": {
      borderTopLeftRadius: "var(--mui-shape-borderRadius)",
      borderTopRightRadius: "var(--mui-shape-borderRadius)",
    },
    "&:last-child": {
      borderBottomLeftRadius: "var(--mui-shape-borderRadius)",
      borderBottomRightRadius: "var(--mui-shape-borderRadius)",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "& .MuiListItem-root": {
      paddingRight: theme.spacing(24),
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      "& .MuiTypography-root": {
        fontWeight: 500,
      },
    },
  },
}));

const suggestions = [
  {
    name: "Caroline Black",
    suggestion: "Lorem ipsum dolor sit amet,  ...",
    statusColor: "success.main",
    lastActive: "13 minutes ago",
  },
  {
    name: "Alfred Copeland",
    suggestion: "Lorem ipsum dolor sit amet,  ...",
    statusColor: "warning.main",
    lastActive: "11 minutes ago",
  },
  {
    name: "Celia Schneider",
    suggestion: "Lorem ipsum dolor sit amet,  ...",
    statusColor: "secondary.main",
    lastActive: "9 minutes ago",
  },
  {
    name: "Max Rogan",
    suggestion: "Lorem ipsum dolor sit amet,  ...",
    statusColor: "error.main",
    lastActive: "28 minutes ago",
  },
];

const SuggestionsList = () => {
  const { t } = useTranslation();

//   function stringToColor() {
//     const colors = [
//       "primary",
//       "secondary",
//       "error",
//       "info",
//       "success",
//       "warning",
//     ];

//     const randomColor = colors[Math.floor(Math.random() * colors.length)];
//     return randomColor;
//   }

  function stringAvatar(name, color) {
    return {
      sx: {
        bgcolor: color, // `${stringToColor()}.dark`
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        borderBottom: "2px solid",
        borderBottomColor: "error.main",
      }}
    >
      <CardContent>
        <Typography sx={{ mb: 4 }} variant="h6" component="div">
          {t("suggestions")}
        </Typography>

        <StyledList disablePadding>
          {suggestions.map((user, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar {...stringAvatar(user.name, user.statusColor)} />
              </ListItemAvatar>
              <div>
                <ListItemText primary={user.name} />
                <div className="flex items-center flex-wrap">
                  <Box
                    className="mie-3 flex items-center gap-1"
                    sx={{ "& i, & svg": { color: user.statusColor } }}
                  >
                    <i className="tabler-circle-filled text-[10px]" />
                    <Typography variant="body2">{user.suggestion}</Typography>
                  </Box>
                  <Typography variant="body2" color="text.disabled">
                    {user.lastActive}
                  </Typography>
                </div>
              </div>
              <ListItemSecondaryAction>
                <Button variant="contained" size="small">
                  Approve
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </StyledList>
      </CardContent>
    </Card>
  );
};

export default SuggestionsList;
