import { Card, CardContent, Typography, Box, Avatar } from "@mui/material";

const StatisticsCard = ({ title, value, color, Icon }) => (
  <Card
    sx={{
      borderRadius: 2,
      boxShadow: 3,
      borderBottom: "2px solid",
      borderBottomColor: color,
    }}
  >
    <CardContent sx={{ bgcolor: "background.paper" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="h4" sx={{ mt: 1 }}>
            {value}{" "}
          </Typography>
        </Box>
        <Avatar sx={{ bgcolor: color }} variant="rounded">
          <Icon sx={{ fontSize: 26 }}/>
        </Avatar>
      </Box>
    </CardContent>
  </Card>
);

export default StatisticsCard;
