import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const TechnologyDetailsCard = ({ result }) => {
  // Define categories with their respective order numbers
  const kurzsteckbriefCategories = [
    { name: "Name der Technologie", order: 1 },
    { name: "Kurzbeschreibung", order: 2 },
    { name: "Technologiebeschreibung", order: 3 },
    { name: "Unterstützungsart", order: 4 },
    { name: "Berücksichtigte Behinderungsformen (übergeordnet)", order: 5 },
    { name: "Reifegrad", order: 6 },
    { name: "Laufzeit von Projekten", order: 7 },
    { name: "Grundvoraussetzungen zur Anwendung", order: 8 },
    { name: "Link(s)", order: 9 },
  ];

  const ausführlicherSteckbriefCategories = [
    { name: "Verfügbae Sprache(n) der Technologie", order: 1 },
    { name: "Finanzierung", order: 2 },
    { name: "Technische Anforderungen", order: 3 },
    { name: "Interoperabilität / Anschlussfähigkeit", order: 4 },
    { name: "Nutzungserfahrungen", order: 5 },
    { name: "ICF-Kategorien", order: 6 },
    { name: "KI-Komponenten nach Periodensystem der KI", order: 7 },
    { name: "KI-Forschungsfelder nach Russel/Norvig", order: 8 },
    { name: "Rechercheupdate Stand", order: 9 },
  ];

  // Function to categorize data into ordered arrays
  function categoriesTableData(result) {
    let kurzsteckbriefData = [];
    let ausfuehrlicherSteckbriefData = [];

    // Function to find order number based on category name
    function getOrder(categories, key) {
      const category = categories.find(cat => cat.name === key);
      return category ? category.order : 999; // Default to a large number if not found
    }

    // Iterate through the JSON keys and categorize them
    for (let key in result) {
      const kurzOrder = getOrder(kurzsteckbriefCategories, key);
      const ausfuehrlichOrder = getOrder(ausführlicherSteckbriefCategories, key);

      if (kurzOrder < 999) {
        kurzsteckbriefData.push({ key: key, value: result[key], order: kurzOrder });
      } else if (ausfuehrlichOrder < 999) {
        ausfuehrlicherSteckbriefData.push({ key: key, value: result[key], order: ausfuehrlichOrder });
      }
    }

    // Sort arrays based on order number
    kurzsteckbriefData.sort((a, b) => a.order - b.order);
    ausfuehrlicherSteckbriefData.sort((a, b) => a.order - b.order);

    return { kurzsteckbriefData, ausfuehrlicherSteckbriefData };
  }

  const { kurzsteckbriefData, ausfuehrlicherSteckbriefData } =  categoriesTableData(result);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{result["Name der Technologie"]}</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <tbody className="align-left">
              {kurzsteckbriefData.map(({ key, value }, index) => (
                <tr key={key}>
                  {index === 0 && (
                    <th
                      rowSpan={kurzsteckbriefData.length}
                      scope="row"
                      className="text-nowrap"
                    >
                      Steckbrief
                    </th>
                  )}
                  <td className="text-nowrap">{key}</td>
                  <td>{value ? value : "-"}</td>
                </tr>
              ))}
              {ausfuehrlicherSteckbriefData.map(({ key, value }, index) => (
                <tr key={key}>
                  {index === 0 && (
                    <th
                      rowSpan={ausfuehrlicherSteckbriefData.length}
                      scope="row"
                      className="text-nowrap"
                    >
                      Weitere Informationen
                    </th>
                  )}
                  <td className="text-nowrap">{key}</td>
                  <td>{value ? value : "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TechnologyDetailsCard;
