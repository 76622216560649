import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeleteConfirmationDialog = ({ open, handleClose, handleConfirm, message }) => {
  const { t } = useTranslation();


  return (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{t('deleteConfirmation')}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{t('cancel')}</Button>
      <Button onClick={handleConfirm} color="secondary" autoFocus>
      {t('delete')}
      </Button>
    </DialogActions>
  </Dialog>
)};

export default DeleteConfirmationDialog;
