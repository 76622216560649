import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DataFilteration.css";

const DataFilteration = ({ dataProp }) => {
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Open by default
  const [data, setData] = useState([]);
  const [expandedCells, setExpandedCells] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [globalExpand, setGlobalExpand] = useState(false); // Track global "Read More" state

  useEffect(() => {
    if (dataProp.length > 0) {
      const initialColumns = [
        { key: '_id', checked: true }, // Add _id as a separate column
        ...Object.keys(dataProp[0]._source).map((key) => ({
            key,
            checked: true,
        }))
    ];
      setAllColumns(initialColumns);
      setSelectedColumns(initialColumns.map((column) => column.key));
    }
  }, [dataProp]);

  const handleSelectChange = (key) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(key)) {
        return prevSelectedColumns.filter((column) => column !== key);
      } else {
        return [...prevSelectedColumns, key];
      }
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleToggleSelectAll = () => {
    if (isAllSelected) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(allColumns.map((column) => column.key));
    }
    setIsAllSelected(!isAllSelected);
  };

  // const handleDeselectAll = () => {
  //   setSelectedColumns([]);
  // };

  useEffect(() => {
    // const extractedData = dataProp.map((item) => item._source);
    const extractedData = dataProp.map((item) => ({
      ...item._source,        // Spread the properties of _source
      _id: item._id           // Add _id to the new object
    }));
  
    setData(extractedData);
  }, [dataProp]);

  const toggleExpand = (rowIndex, key) => {
    setExpandedCells((prevState) => ({
      ...prevState,
      [`${rowIndex}-${key}`]: !prevState[`${rowIndex}-${key}`],
    }));
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  // const headers = Object.keys(columnWidths);

  return (
    <div>
      <div>
      <div className="dropdown-container-all-before-table">
        {/* Global "Read More" Button for Technologiebeschreibung Column */}
        {selectedColumns.includes("Technologiebeschreibung") && (
          <button
            onClick={() => setGlobalExpand((prev) => !prev)}
            className={`btn ${
              globalExpand ? "btn-outline-info" : "btn-outline-info"
            }`}
          >
            {globalExpand ? "Read Less - all" : "Read More - all"}
          </button>
        )}
        <br />
        <br />
      </div>
        <div className="dropdown-container-all-before-table">
          <button
            onClick={toggleDropdown}
            className={`btn ${
              isAllSelected ? "btn-outline-info" : "btn-primary"
            }`}
          >
            Toggle Dropdown
          </button>
        </div>
        <br />
        <br />
        <br />
        {dropdownOpen && (
          <div className="dropdown-menu-container">
            <div className="dropdown-actions">
              <button
                className={`btn ${
                  isAllSelected ? "btn-outline-info" : "btn-outline-info"
                }`}
                onClick={handleToggleSelectAll}
              >
                {isAllSelected ? "Deselect All" : "Select All"}
              </button>
            </div>
            <div className="dropdown-columns">
              {allColumns.map((column) => (
                <label key={column.key} className="dropdown-labels">
                  <input
                    type="checkbox"
                    value={column.key}
                    checked={selectedColumns.includes(column.key)}
                    onChange={() => handleSelectChange(column.key)}
                  />
                  {column.key}
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
      <table border="5" className="main-tablex">

        <thead>
          <tr>
            {selectedColumns.length === 0
              ? Object.keys(data[0] || {}).map((key) => (
                  <th
                    key={key}
                    style={{
                      width: "200px", // Increased width
                      fontSize: "15px",
                      padding: "7px",
                      cursor: "pointer",
                      minWidth: "60px",
                      textAlign: "left", // Center align text
                    }}
                    onClick={() => handleSort(key)}
                  >
                    {key}
                    {sortConfig.key === key
                      ? sortConfig.direction === "asc"
                        ? " 🔼"
                        : " 🔽"
                      : ""}
                  </th>
                ))
              : 
              Object.keys(data[0] || {})
                  .filter((key) => selectedColumns.includes(key))
                  .map((key) => (
                    <th
                      key={key}
                      style={{
                        width: "200px", // Increased width
                        fontSize: "15px",
                        padding: "7px",
                        cursor: "pointer",
                        textAlign: "center", // Center align text
                      }}
                      onClick={() => handleSort(key)}
                    >
                      {key}
                      {sortConfig.key === key
                        ? sortConfig.direction === "asc"
                          ? " 🔼"
                          : " 🔽"
                        : ""}
                    </th>
                  ))}
              {/* <th>_id</th> */}
          </tr>
        </thead>
        <tbody>
          {/* {console.log('✌️data sssssss--->', data)} */}
          {data
            // .filter((item) => !(item["Name der Technologie"] === null))
            .map((item, index) => (
              
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                }}
              >
                {selectedColumns.length === 0
                  ? Object.keys(item || {}).map((key) => {
                      let text = (item[key] || "").toString().trim();
                      const words = text.split(" ");
                      const isExpanded =
                        globalExpand || expandedCells[`${index}-${key}`];
                      return (
                        <td
                          key={key}
                          className="wrapped-data"
                          style={{
                            width: "200px", // Increased width
                            fontSize: "15px",
                            whiteSpace: "pre-wrap",
                            padding: "7px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            lineHeight: "1.5",
                            minWidth: "60px",
                            textAlign: "left", // Center align text
                          }}
                        >
                          {/*  */}
                          {isExpanded ? text : words.slice(0, 10).join(" ")}
                          {words.length > 10 && (
                            <span
                              onClick={() => toggleExpand(index, key)}
                              style={{ color: "blue", cursor: "pointer" }}
                            >
                              {isExpanded ? " read less" : "... read more"}
                            </span>
                          )}
                        </td>
                      );
                    })
                  : Object.keys(item || {})
                      .filter((column) => selectedColumns.includes(column))
                      .map((column) => {
                        // console.log('item[column] --->', item["_id"]);
                        const text = (item[column] || "").toString().trim();
                        // console.log('✌️text --->', text);
                        const words = text.split(" ");

                        const isExpanded = globalExpand || expandedCells[`${index}-${column}`];

                        return (
                          <td
                            key={column}
                            className="wrapped-data"
                            style={{
                              width: "200px", // Increased width
                              whiteSpace: "pre-wrap",
                              padding: "5px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              lineHeight: "1.2",
                              textAlign: "center", // Center align text
                              margin: "2px",
                            }}
                          >
                            {column === "Link(s)" ? (
                              <div className="link-property">
                                <a
                                  href={text}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {text}
                                </a>
                              </div>
                            ) : (
                              <>
                                {isExpanded
                                  ? text
                                  : words.slice(0, 5).join(" ")}
                                {words.length > 50 && (
                                  <span
                                    onClick={() => toggleExpand(index, column)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {isExpanded
                                      ? " read less"
                                      : "... read more"}
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                          
                        );
                      })}
                    <td>{data._id}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataFilteration;