import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Admin Components
import AdminPanel from '../components/admin/AdminPanel';
import Users from '../components/admin/users/Users';
import UserRoles from '../components/admin/user_roles/UserRoles';

// Material UI Components
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Custom Components
import Sidebar from '../components/admin/Sidebar';

const AdminPanelLayout = () => {

  const { t } = useTranslation();

  const drawerWidth = 240;
  const location = useLocation();

  const getCurrentPath = (pathname) => {
    switch (pathname) {
      case '/admin':
        return t('home');
      case '/admin/users':
        return t('users');
      case '/admin/user-roles':
        return t('userRoles');
      default:
        return t('adminPanelTitle');
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {getCurrentPath(location.pathname)}
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, pt: 12 }}
      >
          <Routes>
            <Route path="/" element={<AdminPanel />} />
            <Route path="users" element={<Users />} />
            <Route path="user-roles" element={<UserRoles />} />
          </Routes>
      </Box>
    </Box>
  );
};

export default AdminPanelLayout;
