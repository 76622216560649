import React, { useState, useEffect } from "react";
import axios from "axios";
import formFields from "./formFields";
import { Navbar, Button } from 'react-bootstrap';
import { labelMapping } from "./formFields";
import "./CreateForm.css";

const PYTHON_URL = process.env.REACT_APP_PYTHON_URL;
const initialFormFields = { id: "", ...formFields }; // Renamed for clarity

const UpdateForm = ({ fetchedDataById = {} }) => {

  const [formData, setFormData] = useState(initialFormFields);
  const [apiUrl, setApiUrl] = useState('');
  const [hits, setHits] = useState(0);

  // Update formData when fetchedDataById changes
  useEffect(() => {
    if (fetchedDataById) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...fetchedDataById
      }));
    }
  }, [fetchedDataById]);

  // Retrieve hits from localStorage on component mount
  useEffect(() => {
    const storedHits = localStorage.getItem('hits');
    if (storedHits !== null) {
      setHits(Number(storedHits));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "id") {
      const url = `${PYTHON_URL}/technologies/updatebyid/${value}`;
      setApiUrl(url);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "" && key !== "id")
    );

    try {
      const response = await axios.post(apiUrl, {
        updated_data: filteredData,
      });
      if (response.data.result.error) {
        alert(response.data.result.error);
      } else {
        alert(response.data.result);
      }
    } catch (error) {
      console.error("Error updating data", error);
    }
  };

  const isSubmitDisabled = !formData.id;

  return (
    <>
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>

      <br />
      <div className="hit-center">
        <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
      </div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key} className="form-group">
            <label>{key === "id" ? "Id*" : labelMapping[key]}:</label>
            {/* <label>{key === "id" ? "Id*" : key}:</label> */}
            <input
              type="text"
              name={key}
              value={formData[key]}
              onChange={handleChange}
              className="form-input"
              placeholder={key === "id" ? "Enter Id to update the record" : ""}
            />
          </div>
        ))}
        <Button type="submit" className="btn btn-primary" disabled={isSubmitDisabled}>
          Update
        </Button>
      </form>
    </>
  );
};

export default UpdateForm;
