import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

// ProtectedRoute component to guard routes for authenticated users only
export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/" />;
};

// LoginRoute component to redirect authenticated users away from login page
export const LoginRoute = ({ element }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/dashboard" /> : element;
};
