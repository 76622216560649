import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Chip,
  FormHelperText,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

// Component for displaying and managing the role form
const RoleForm = ({
  open,
  handleClose,
  role,
  setRole,
  handleSave,
  editMode,
  privilegesOptions,
}) => {
  const { t } = useTranslation();

  // Using useState hook to manage form errors
  const [errors, setErrors] = useState({
    role_name: false,
    description: false,
    privileges: false,
  });

  // Function to validate form fields
  const validateForm = () => {
    const newErrors = {
      role_name: role.role_name.trim() === "",
      description: role.description.trim() === "",
      privileges: role.privileges.length === 0,
    };

    setErrors(newErrors);

    // Check if any field is empty
    return Object.values(newErrors).every((error) => !error);
  };

  // Function to handle the change of form fields
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "privileges" && value.includes("all")) {
      value = ["all"];
      setRole({ ...role, [name]: ["all"] });
    }
    setRole({ ...role, [name]: value });
  };

  // Handler for save button click
  const handleSaveClick = () => {
    if (validateForm()) {
      handleSave();
      handleClose();
    }
  };

  // Handler for modal close
  const handleModalClose = () => {
    // Reset form errors when the modal closes
    setErrors({
      role_name: false,
      description: false,
      privileges: false,
    });

    handleClose();
  };

  // Inline style for modal content
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box sx={{ ...style, width: 400 }}>
        <h2>{editMode ? t("editRole") : t("addRole")}</h2>
        <TextField
          margin="normal"
          label={t("roleName")}
          name="role_name"
          fullWidth
          value={role.role_name}
          error={errors.role_name}
          helperText={errors.role_name && t("roleNameRequired")}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          label={t("description")}
          name="description"
          fullWidth
          value={role.description}
          error={errors.description}
          helperText={errors.description && t("descriptionRequired")}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal" error={errors.privileges}>
          <InputLabel id="privileges-label">{t("privileges")}</InputLabel>
          <Select
            labelId="privileges-label"
            id="privileges"
            name="privileges"
            value={role.privileges}
            label={t("privileges")}
            multiple
            onChange={handleChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={privilegesOptions[value].label}
                    color={privilegesOptions[value].color}
                    variant="outlined"
                  />
                ))}
              </Box>
            )}
          >
            {Object.keys(privilegesOptions).map((key) => (
              <MenuItem key={key} value={key}>
                {privilegesOptions[key].label}
              </MenuItem>
            ))}
          </Select>
          {errors.privileges && (
            <FormHelperText>{t("privilegesRequired")}</FormHelperText>
          )}
        </FormControl>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveClick}
          >
            {t("save")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RoleForm;
