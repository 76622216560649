import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Paper,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../theme";
import AuthForm from "../components/auth/AuthForm";

const AuthLayout = () => {
  const { t } = useTranslation();
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const { isSmallScreen } = useResponsive();

  const handleRegistrationSuccess = () => {
    setShowRegisterForm(false); // Switch to login form
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f4f5fa",
        padding: isSmallScreen ? 2 : 3,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            padding: isSmallScreen ? 2 : 0,
          }}
        >
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: isSmallScreen ? "auto" : "70vh",
              border: "none",
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{
                padding: isSmallScreen ? 3 : 4,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                align="center"
              >
                {showRegisterForm ? t("register") : t("login")}
              </Typography>

              {/* Auth Forms */}
              <Box sx={{ padding: isSmallScreen ? 1 : 2 }}>
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <CardContent>
                    <AuthForm
                      isRegister={showRegisterForm}
                      onSuccess={
                        showRegisterForm ? handleRegistrationSuccess : null
                      }
                    />
                  </CardContent>
                </Card>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setShowRegisterForm(!showRegisterForm);
                }}
              >
                {showRegisterForm ? t("backToLogin") : t("register")}
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Container>
    </Box>
  );
};

export default AuthLayout;
